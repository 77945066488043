import { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { isDevelopment } from '@/utils/isDevelopment';
import { isNonNullable } from '@/utils/isNonNullable';
import { getVideoConfig, getVideoDataFromUrl } from '@/utils/videoPlayers';
import { Carousel } from '@/components/Carousel';
import { EmbedIframe } from '@/components/EmbedIframe';
import { Warning } from '@/components/Warning';
import { useEntry } from '../App.hooks';
type Props = {
  className?: string;
};
export function AppMediaCarousel({
  className
}: Props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCarouselItem, setActiveCarouselItem] = useState<{
    video: string | null;
    thumbnail: string | null;
  }>({
    video: null,
    thumbnail: null
  });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const entry = useEntry();
  const videoData = 'data' in entry && entry.data && entry.data.video ? entry.data.video : [];
  const screenshots = 'data' in entry && entry.data && entry?.data.screenshots ? entry.data.screenshots : [];
  const carouselItems = useMemo(() => {
    return [...videoData.map(async video => {
      if (!video) return null;
      return await getVideoConfig(getVideoDataFromUrl(video));
    }).filter(isNonNullable), ...screenshots.map(thumbnail => {
      if (!thumbnail) return null;
      return {
        video: null,
        thumbnail: thumbnail.startsWith('//') ? `https:${thumbnail}` : thumbnail
      };
    }).filter(isNonNullable)];
  }, [videoData]);
  useEffect(() => {
    setIsVideoPlaying(false);
    const setActiveItem = async () => {
      const item = await carouselItems?.[activeIndex];
      if (item && 'thumbnail' in item) {
        setActiveCarouselItem(item);
      }
    };
    setActiveItem();
  }, [activeIndex, carouselItems]);
  if ('error' in entry) return null;
  if (!entry.data?.video && !entry.data?.screenshots) {
    if (isDevelopment()) {
      return <Warning>AppMediaCarousel: No media found for this app.</Warning>;
    }
    return null;
  }
  if (carouselItems?.length === 0) return null;
  return <div className={clsx('w-full', className)} data-sentry-component="AppMediaCarousel" data-sentry-source-file="AppMediaCarousel.tsx">
			{isVideoPlaying && activeCarouselItem.video && <EmbedIframe url={activeCarouselItem.video} width="100%" height="100%" className="aspect-[16/11]" />}

			{activeCarouselItem?.thumbnail && !isVideoPlaying && <div className="relative aspect-[16/11] flex items-center justify-center">
					{activeCarouselItem.video && <button className="absolute z-10 px-12 py-5 bg-gray-500/80 rounded-xl transition duration-100 ease-in-out hover:bg-[#4b71fc]/90" onClick={() => setIsVideoPlaying(true)}>
							<svg viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-10 translate-x-1">
								<path d="M18 11.0017L-9.08524e-07 21.394L0 0.609374L18 11.0017Z" fill="white" />
							</svg>
						</button>}

					<Image src={activeCarouselItem.thumbnail} alt="thumbnail" fill className="object-contain" />
				</div>}

			{Array.isArray(carouselItems) && <Carousel itemsShown={3} skip={1} loop setActiveSlide={setActiveIndex} className="mt-4">
					{
      // @ts-ignore TODO - Solve type problems for thumbnail in the next line
      carouselItems.map(({
        thumbnail
      }, index) => {
        if (!thumbnail) return null;
        return <div className="relative w-full aspect-[16/11]" key={index}>
									<Image src={thumbnail} alt="thumbnail" fill className="object-contain" />
								</div>;
      })}
				</Carousel>}
		</div>;
}